// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-shadow:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1) !important;
}

.card-hover {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.card-hover:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.1) !important;
}

.social-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.card-hover:hover .social-overlay {
    opacity: 1;
    pointer-events: auto;
}

.social-icon {
    color: #dc3545;
    transition: all 0.2s ease-in-out;
    transform: translateY(20px);
    opacity: 0;
}

.card-hover:hover .social-icon {
    transform: translateY(0);
    opacity: 1;
}

.social-icon:hover {
    color: #c82333;
    transform: scale(1.1) !important;
}

/* Staggered animation for icons */
.social-icon:nth-child(1) {
    transition-delay: 0.1s;
}

.social-icon:nth-child(2) {
    transition-delay: 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/Components/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,iDAAiD;AACrD;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,iDAAiD;AACrD;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,UAAU;IACV,gCAAgC;IAChC,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,gCAAgC;IAChC,2BAA2B;IAC3B,UAAU;AACd;;AAEA;IACI,wBAAwB;IACxB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,gCAAgC;AACpC;;AAEA,kCAAkC;AAClC;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".hover-shadow:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 5px 15px rgba(0,0,0,0.1) !important;\n}\n\n.card-hover {\n    transition: all 0.3s ease-in-out;\n    overflow: hidden;\n}\n\n.card-hover:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 8px 20px rgba(0,0,0,0.1) !important;\n}\n\n.social-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(255, 255, 255, 0.95);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 2rem;\n    opacity: 0;\n    transition: all 0.3s ease-in-out;\n    pointer-events: none;\n}\n\n.card-hover:hover .social-overlay {\n    opacity: 1;\n    pointer-events: auto;\n}\n\n.social-icon {\n    color: #dc3545;\n    transition: all 0.2s ease-in-out;\n    transform: translateY(20px);\n    opacity: 0;\n}\n\n.card-hover:hover .social-icon {\n    transform: translateY(0);\n    opacity: 1;\n}\n\n.social-icon:hover {\n    color: #c82333;\n    transform: scale(1.1) !important;\n}\n\n/* Staggered animation for icons */\n.social-icon:nth-child(1) {\n    transition-delay: 0.1s;\n}\n\n.social-icon:nth-child(2) {\n    transition-delay: 0.2s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
