// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(111.4deg, rgb(246, 4, 26) 0.4%, rgb(251, 139, 34) 100.2%);
}

.gradient-form{
  max-height: 75vh;
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/LoginForm/Login.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,mBAAmB;;EAEnB,+BAA+B;;EAG/B,qEAAqE;EACrE,qFAAqF;AACvF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,8BAA8B;IAC9B,iCAAiC;EACnC;AACF","sourcesContent":[".gradient-custom-2 {\n  /* fallback for old browsers */\n  background: #fccb90;\n\n  /* Chrome 10-25, Safari 5.1-6 */\n  background: -webkit-linear-gradient(111.4deg, rgb(246, 4, 26) 0.4%, rgb(251, 139, 34) 100.2%);\n\n  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\n  background: linear-gradient(111.4deg, rgb(246, 4, 26) 0.4%, rgb(251, 139, 34) 100.2%);\n}\n\n.gradient-form{\n  max-height: 75vh;\n}\n\n@media (min-width: 768px) {\n  .gradient-form {\n    height: 100vh !important;\n  }\n}\n@media (min-width: 769px) {\n  .gradient-custom-2 {\n    border-top-right-radius: .3rem;\n    border-bottom-right-radius: .3rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
