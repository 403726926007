// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    padding-top: 100px;
}

.sidebar .nav-link {
    color: #fff;
    padding: 10px 20px;
    margin: 5px 0;
}

.sidebar .nav-link:hover {
    background-color: rgba(255,255,255,0.1);
}

.sidebar.closed .nav-link {
    text-align: center;
}

.sidebar.closed .nav-link span {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/AdminNavbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".sidebar {\n    padding-top: 100px;\n}\n\n.sidebar .nav-link {\n    color: #fff;\n    padding: 10px 20px;\n    margin: 5px 0;\n}\n\n.sidebar .nav-link:hover {\n    background-color: rgba(255,255,255,0.1);\n}\n\n.sidebar.closed .nav-link {\n    text-align: center;\n}\n\n.sidebar.closed .nav-link span {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
