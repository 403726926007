// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-hover {
    transition: all 0.3s ease-in-out;
}

.card-hover:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.1) !important;
}

.icon-circle {
    width: 80px;
    height: 80px;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.card-hover:hover .icon-circle {
    transform: scale(1.1);
    background-color: #dc3545;
    color: white;
}

.card-hover:hover .icon-circle .text-danger {
    color: white !important;
}

.form-field {
    transition: all 0.3s ease-in-out;
}

.form-field:focus {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(220, 53, 69, 0.1) !important;
}

.btn-hover {
    transition: all 0.3s ease-in-out;
}

.btn-hover:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(220, 53, 69, 0.2);
}

.status-message {
    animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/ContactForm/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;IAC3B,iDAAiD;AACrD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;IAC3B,wDAAwD;AAC5D;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;IAC3B,6CAA6C;AACjD;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ","sourcesContent":[".card-hover {\n    transition: all 0.3s ease-in-out;\n}\n\n.card-hover:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 8px 20px rgba(0,0,0,0.1) !important;\n}\n\n.icon-circle {\n    width: 80px;\n    height: 80px;\n    background-color: #f8f9fa;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    transition: all 0.3s ease-in-out;\n}\n\n.card-hover:hover .icon-circle {\n    transform: scale(1.1);\n    background-color: #dc3545;\n    color: white;\n}\n\n.card-hover:hover .icon-circle .text-danger {\n    color: white !important;\n}\n\n.form-field {\n    transition: all 0.3s ease-in-out;\n}\n\n.form-field:focus {\n    transform: translateY(-2px);\n    box-shadow: 0 5px 15px rgba(220, 53, 69, 0.1) !important;\n}\n\n.btn-hover {\n    transition: all 0.3s ease-in-out;\n}\n\n.btn-hover:hover:not(:disabled) {\n    transform: translateY(-2px);\n    box-shadow: 0 5px 15px rgba(220, 53, 69, 0.2);\n}\n\n.status-message {\n    animation: slideUp 0.3s ease-out;\n}\n\n@keyframes slideUp {\n    from {\n        opacity: 0;\n        transform: translateY(10px);\n    }\n    to {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
